import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=23287bc3"
import script from "./_id.vue?vue&type=script&lang=js"
export * from "./_id.vue?vue&type=script&lang=js"
import style0 from "./_id.vue?vue&type=style&index=0&id=23287bc3&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EmptyState: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/EmptyState/EmptyState.vue').default,WidgetLayoutRowCellSingle: require('/app/components/Widget/WidgetLayoutRowCellSingle.vue').default,WidgetLayout: require('/app/components/Widget/WidgetLayout.vue').default,CompositionLayoutSingle: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/CompositionLayoutSingle.vue').default,Composition: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/Composition.vue').default,NoFremiumAccess: require('/app/components/NoFremiumAccess.vue').default})
